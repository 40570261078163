<template>
    <div>
        <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
            {{ successMessage }}
        </div>
        <b-card>
            <b-row>
                <div class="col-12 mt-16">
                    <b-form @submit.prevent="addUser" v-if="show">
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                MarketPlace Information
                            </h4>
                        </div>
                        <div class="row">
                            <!-- <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Category:" label-for="category">
                                    <b-form-input id="category" placeholder="Enter category"
                                        v-model="category" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div> -->
                           

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Brand Model:"
                                    label-for="brand_models">
                                    <b-form-input id="brand_models" placeholder="Select brand model" v-model="brand_models.title" disabled></b-form-input>
                                </b-form-group>
                            </div>
    

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Year:" label-for="year">
                                    <b-form-input id="year" placeholder="Enter year"
                                        v-model="year" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                        <b-form-group id="input-group-2" label="Brand Name:" label-for="brands">
                            <b-form-input id="brands" placeholder="Select brand id" v-model="brands.name" disabled>
                            </b-form-input>
                        </b-form-group>
                    </div>


                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Registration In:" label-for="registration_in">
                                    <b-form-input id="registration_in" placeholder="Enter registyration  in"
                                        v-model="registration_in" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Registration Number:" label-for="registration_number">
                                    <b-form-input id="registration_number" placeholder="Enter registration  number"
                                        v-model="registration_number" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Engine Number:" label-for="engine_number">
                                    <b-form-input id="engine_number" placeholder="Enter engine nnumber"
                                        v-model="engine_number" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Color:" label-for="color">
                                    <b-form-input id="color" placeholder="Enter color"
                                        v-model="color" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="City:" label-for="city">
                                    <b-form-input id="city" placeholder="Enter city"
                                        v-model="city" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Area:" label-for="area">
                                    <b-form-input id="area" placeholder="Enter area"
                                        v-model="area" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Country:" label-for="country">
                                    <b-form-input id="country" placeholder="Enter country"
                                        v-model="country" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Mileage:" label-for="mileage">
                                    <b-form-input id="mileage" placeholder="Enter mileage"
                                        v-model="mileage" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Price:" label-for="price">
                                    <b-form-input id="price" placeholder="Enter price"
                                        v-model="price" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Kilometer Second Driven:" label-for="KMs_driven">
                                    <b-form-input id="KMs_driven" placeholder="Enter kilometer second"
                                        v-model="KMs_driven" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Fuel Type:" label-for="fuel_type">
                                    <b-form-input id="fuel_type" placeholder="Enter fuel type"
                                        v-model="fuel_type" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Transmission Type:" label-for="transmission_type">
                                    <b-form-input id="transmission_type" placeholder="Enter transmission type"
                                        v-model="transmission_type" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Engine Capacity:" label-for="engine_capacity">
                                    <b-form-input id="engine_capacity" placeholder="Enter engine capacity"
                                        v-model="engine_capacity" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Description:" label-for="description">
                                    <b-form-input id="description" placeholder="Enter description"
                                        v-model="description" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Features:" label-for="features">
                                    <b-form-input id="features" placeholder="Enter features"
                                        v-model="features" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Assemble:" label-for="assembly">
                                    <b-form-input id="assembly" placeholder="Enter assembly"
                                        v-model="assembly" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Satus:" label-for="status">
                                    <b-form-input id="status" placeholder="Enter status"
                                        v-model="status" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>
                                      <!-- Document Upload Field -->
                    
                                
                    <!-- images   code  here  -->
                    <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Vehicle Documents:" label-for="images">
                                    <!-- <input
                                        type="file"
                                        accept="image/*"
                                        multiple
                                        @change="onProfilePictureChange"
                                    /> -->
                                    <div v-if="documentPreviews.length" style="margin-left: 3px; margin-bottom: 15px">
                                        <img
                                            v-for="(doc, index) in documentPreviews"
                                            :key="index"
                                            :src="doc"
                                            alt="Vehicle Document"
                                            width="100"
                                            height="100"
                                            style="margin-right: 10px"
                                        />
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </b-form>
                </div>
                <div v-if="codeActive" class="col-12 mt-24 hljs-container" :class="{ active: codeActiveClass }">
                    <pre v-highlightjs>
              <code class="hljs html">
                {{ codeText }}
              </code>
            </pre>
                </div>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BSpinner,
} from "bootstrap-vue";
// import axios from "axios";
import axios from "../../../axios";
import { BToast } from "bootstrap-vue";
import code from "@/view/components/data-entry/form/code";

export default {
    data() {
        return {
            show: true,
            codeText: code.introduction,
            codeActive: false,
            codeActiveClass: false,
            selectedOption: "",
            commission: "",
            selectedCarType: "",
            inputField1: "",
            inputField2: "",
            inputField3: "",
            showModal: false,
            isLoading: false,

            year: "",
            brand_models: '',
            // brand_id: '',
            brands:'',
            registration_in:"",
            registration_number: '',
            engine_number: '',
            color: '',
            city: '',
            area: '',
            country: '',
            mileage: '',
            price: '',
            KMs_driven: '',
            fuel_type: '',
            transmission_type: '',
            engine_capacity: '',
            description: '',
            features: '',
            assembly: '',
            status: '',
            category:"",
            user_id:"",
            documents: [], 
            documentFiles: [],
            documentPreviews: [],
            image: [], 
            
                };
            },
            components: {
                BRow,
                BCol,
                BCard,
                BButton,
                BForm,
                BFormGroup,
                BFormCheckboxGroup,
                BFormCheckbox,
                BFormSelect,
                BFormInput,
                BToast, // Add this line
                BSpinner,
            },
            mounted() {
             
                    axios.get('brandModel')
                        .then(response => {
    
                            this.brandModels = response.data.data.map(model => ({
                                value: model.id,  
                                text: model.title 
                            }));
                            console.log(this.brandModels);
                        })
                        .catch(error => {
                            console.log(error);
                        });
            }, 
    created() {
        // Load the clients data when the component is created
        const userId = this.$route.params.id;
        axios
            .get(`marketPlaceShow/${userId}`)
            .then((response) => {
                this.editedUser = response.data.data;
                this.year = this.editedUser.year;
                this.brand_models = this.editedUser.brand_models;
                this.brands = this.editedUser.brands;
                this.registration_number = this.editedUser.registration_number;
                this.engine_number = this.editedUser.engine_number;
                this.registration_in = this.editedUser.registration_in;
                this.color = this.editedUser.color;
                this.city = this.editedUser.city;
                this.area = this.editedUser.area;
                this.country = this.editedUser.country;
                this.mileage = this.editedUser.mileage;
                this.price = this.editedUser.price;
                this.KMs_driven = this.editedUser.KMs_driven;
                this.fuel_type = this.editedUser.fuel_type;
                this.transmission_type = this.editedUser.transmission_type;
                this.engine_capacity = this.editedUser.engine_capacity;
                this.description = this.editedUser.description;
                this.features = this.editedUser.features;
                this.assembly = this.editedUser.assembly;
                this.status = this.editedUser.status;
                this.image = this.editedUser.image;
                this.category = this.editedUser.category;
                this.user_id = this.editedUser.user_id;
                // this.documents = this.editedUser.documents;
                // this.image = this.editedUser.image;
                this.documentPreviews = this.editedUser.documents.map(
                doc => `https://wossdb.wossautoid.com/${doc.documents}`
            );
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            });
    },
    methods: {
    
        showMsgBoxOne() {
            debugger;
        },
        onSubmit(event) {
            event.preventDefault();
            alert(JSON.stringify(this.form));
            // debugger
        },
        // onProfilePictureChange(event) {
        //     const files = event.target.files;
        //     if (files && files.length > 0) {
        //         this.image = Array.from(files).map(file => URL.createObjectURL(file));
        //     }
        // },
      
        codeClick() {
            this.codeActive = !this.codeActive;
        },

        saveOwnCar() {
            this.showModal = false;
        },
        // downloadDocument(docPath) {
        //     const link = document.createElement('a');
        //     link.href = `https://wossdb.wossautoid.com/${docPath}`;
        //     link.download = docPath.split('/').pop();
        //     link.click();
        // },
      
    },
};
</script>
